const AWS = require("aws-sdk");

export function uploadFile(getCreds, progress, name, keyPrefix, type, content) {
  console.log("Inside main file");
  return new Promise(function (resolve, reject) {
    console.log("credentials is calling*********");
    console.log(getCreds);
    // console.log("response in credentialls" + JSON.stringify(response));
    try {
        var UploadCreds = getCreds.Credentials;
        var bucket = getCreds.bucket;
        var accessUrl = getCreds.accessUrl;
        AWS.config = new AWS.Config({
          accessKeyId: UploadCreds.AccessKeyId,
          secretAccessKey: UploadCreds.SecretAccessKey,
          sessionToken: UploadCreds.SessionToken,
        //   region: "ap-south-1",
        });
        var bucket = new AWS.S3({
          accessKeyId: UploadCreds.AccessKeyId,
          secretAccessKey: UploadCreds.SecretAccessKey,
          sessionToken: UploadCreds.SessionToken,
          params: { Bucket: bucket, ACL: "private" },
        });
        var params = { Key: keyPrefix+"/"+name, ContentType: type, Body: content };
        bucket
          .upload(params)
          .on("httpUploadProgress", function (evt) {
            //console.log('Progress:', ':', evt.loaded, '/', evt.total)
            progress(Math.round((evt.loaded / evt.total) * 100));
          })
          .send(function (err, data) {
            if (err) {
              reject(err);
            } else {
              data["accessUrl"] = accessUrl;
              console.log("data ==" + JSON.stringify(data));
              resolve({ data: [data] });
            }
          });
    }
    catch (err) {
        console.log(err);
    }
  });
}
