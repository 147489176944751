<template style="z-index:1000">
<div>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"/>
  <template >
    <h2><center>HomePage Testimonials</center></h2>
    <br/><br/>
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        Add Testimonials
      </h5>

      
      </div>
      <validation-observer ref="registerForm">
        <b-form @reset="onReset" class="auth-register-form mt-2 ml-2 mr-2">
          <b-form-group label="Title" label-for="title">
              <b-form-input id="title" v-model="title" name="title"
                 />
          </b-form-group>
          <b-form-group label="Description" label-for="Description">
              <b-form-input id="Description" v-model="description" name="Description"
             />
          </b-form-group>
          <b-form-group label="Image" label-for="image">
              <b-form-file v-on:change="retakePhoto" v-model="image" 
              />
          </b-form-group>
          <b-form-group label="Rating" label-for="rating">
              <b-form-input id="rating" v-model="rating" name="rating"
             />
          </b-form-group>


          <b-button variant="primary" size="lg" type="submit" @click.prevent="addTestimonial">
            Save
          </b-button>
					<br/><small class="text-danger">{{ errors }}</small>
        </b-form>
        <b-modal ok-only v-model="success">
					Your Homepage Testimonials Data is uploaded!
				</b-modal>
      </validation-observer>


  </template>
  <Testimonials/>
  
</div>
</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapMutations, mapActions, mapState } from "vuex";
import Password from "vue-password-strength-meter";
import { uploadFile } from "../../../s3upload/index";
import Testimonials from './homepageTestimonialsList.vue'
export default {
  components: {
    BSidebar,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    Testimonials,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    Password
  },
  model: {

  },
  props: {
    

  },
	name: "TestimonialsAdd",
  data() {
    return {
      imageURL:'',
      title:'',
      description:'',
      rating: 0,
      image:''
    }
  },
  methods :{
    ...mapActions({ addTestimonials: "adminTestimonials/addTestimonials", getS3Cred: "uploadToS3/getS3Cred", }),
    async onReset(){
      this.imageURL = '',
      this.title = '',
      this.description = '',
      this.rating = 0,
      this.image = ''
    },
    async retakePhoto(event) {
      console.log("%%%");
      console.log(event.target.name);
      console.log(event.target.files);
      const selectedFile = event.target.files;
      const fileName = selectedFile[0].name;
      await this.getS3Cred(fileName);
      const result = this.getS3CredFromStore;
      console.log("Result in m2 : ", result.accessUrl);
        this.imageURL = result.accessUrl
      await uploadFile(
        result,
        function () {},
        fileName,
        "uploads",
        selectedFile[0].type,
        selectedFile[0]
      );
    },
    async addTestimonial() {
    await this.addTestimonials({title: this.title, description: this.description, imageURL: this.imageURL, rating: this.rating})
        this.onReset()
		    component('TestimonialsList').loadtestimonialss()
    },
    
  },
  computed: {
    ...mapState({
      
      getS3CredFromStore: (state) => state.uploadToS3.S3Cred,
      errors: (state) => state.adminTestimonials.errors,
			success: (state) => state.adminTestimonials.success
    }),
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
