<template>
	<div class="table-section" style="margin-left:30px;margin-right:30px;">
	  
	  <div class="m-2" >
		<b-row>
		  <b-col
			cols="12"
			md="6"
			class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
		  >
		  <label>Show</label>
		  <v-select
			v-model="perPage"
			:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
			:options="perPageOptions"
			:clearable="false"
			class="per-page-selector d-inline-block mx-50"
		  />
		  <label>entries</label>
  
		  </b-col>
		  <b-col
			cols="12"
			md="6"
		  >
		  
  
		</b-col>
  
		</b-row>
	  </div>
	  <b-table
		ref="refCategoyListTable"
		class="position-relative"
		:items="testimonials"
		responsive
		:fields="tableColumns"
		primary-key="id"
		show-empty
		empty-text="No matching records found"
	  >
	  <template #cell(title)="data">
		<div class="text-nowrap">
		  {{ data.item.title }}
		</div>
	  </template>
	  <template #cell(description)="data">
		<div class="text-nowrap">
		  {{ data.item.description }}
		</div>
	  </template>
	  <template #cell(rating)="data">
		<div class="text-nowrap">
		  {{ data.item.rating }}
		</div>
	  </template>
	  <template #cell(imageUrl)="data">
		<div class="text-nowrap">
			<!-- {{data.item.imageUrl}} -->
		  <img crossorigin="anonymous" class="image" v-bind:src="data.item.imageUrl" alt="">
		</div>
	  </template>
	  <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item @click="deleteTest(data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
	 
  
	</b-table>
	<div class="mx-2 mb-2">
	  <b-row>
		<b-col
		  cols="12"
		  sm="6"
		  class="d-flex align-items-center justify-content-center justify-content-sm-start"
		>
		  <span class="text-muted">Showing {{from }} to {{ to }} of {{ totaltestimonials }} entries</span>
		</b-col>
		<b-col
		  cols="12"
		  sm="6"
		  class="d-flex align-items-center justify-content-center justify-content-sm-end"
		>
		<b-pagination
		  v-model="currentPage"
		  :total-rows="totaltestimonialss"
		  :per-page="perPage"
		  first-number
		  last-number
		  class="mb-0 mt-1 mt-sm-0"
		  prev-class="prev-item"
		  next-class="next-item"
		>
		  <template #prev-text>
			<feather-icon
			  icon="ChevronLeftIcon"
			  size="18"
			/>
		  </template>
		  <template #next-text>
			<feather-icon
			  icon="ChevronRightIcon"
			  size="18"
			/>
		  </template>
		</b-pagination>
	  </b-col>
	  </b-row>
	</div>
  
  
  
	</div>
  </template>
  <script>
  import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,BPopover
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import { mapMutations, mapActions, mapState } from "vuex";
  import BCardCode from '@core/components/b-card-code'
  
  export default {
	name: "TestimonialsList",
	data() {
	  return {
		perPage:10,
		currentPage : 1,
		perPageOptions : [10, 25, 50, 100],
		searchQuery:'',
		fetchtestimonials:[],
		tableColumns :[
		  { key: 'title'},
		  { key: 'description' },
		  { key: 'Rating' },
		  { key: 'imageUrl' },
		  { key: 'actions' }

		],
		skip:0,
		from:0,
		to:0,
	  }
	},
	async mounted (){
		var query = {
			skip:0,
			limit:this.perPage
		}
		await this.loadtestimonialss(query)
		this.$root.$on('TestimonialsAdd',() => {
			this.loadtestimonialss()
		})
	},
	
	computed:{
	  ...mapState({
		testimonials :(state) =>{
  
		 return state.adminTestimonials.testimonials;
	   },
	   totaltestimonialss: (state) =>{
		 return state.adminTestimonials.totaltestimonials;
	   }
	  })
	},
	watch:{
	  currentPage:{
		handler(){
		  this.skip = (this.currentPage - 1) * this.perPage
		  this.reload()
		}
	  },
	  perPage:{
		handler(){
		  this.skip = (this.currentPage - 1) * this.perPage
		  this.reload()
		}
	  },
	  categories:{
		handler(){
		  const testimonialsLength = this.testimonials.length;
		  this.from = this.perPage * (this.currentPage - 1) + (testimonialsLength ? 1 : 0);
		  this.to =  this.perPage * (this.currentPage - 1) + testimonialsLength;
		}
	  }
	},
	methods:{
	  ...mapActions({
		loadtestimonialss:"adminTestimonials/fetchAlltestimonials",
		deleteTestimonials:"adminTestimonials/deleteTestimonials"
	  }),
	  reload(){
      this.loadtestimonialss({skip:this.skip,limit:this.perPage})
    },
	async deleteTest(id){
		await this.deleteTestimonials(id)
		this.loadtestimonialss({skip:this.skip,limit:this.perPage})
	}
	  
	  
	},
	components: {
	  BCard,
	  BRow,
	  BCol,
	  BFormInput,
	  BButton,
	  BTable,
	  BMedia,
	  BAvatar,
	  BLink,
	  BBadge,
	  BDropdown,
	  BDropdownItem,
	  BPagination,
	  BPopover,
	  vSelect,
	  BCardCode
	},
  
  }
  
  </script>
  <style>
  .card-header {
	background-color:#ebe9f1;
  }
  .image {
    width: 60px;
    height: 58px;
  }
  </style>
  